export enum PageEnum {
  // login
  BASE_LOGIN = '/login',
  BASE_LOGIN_NAME = 'login',
  // redirect
  REDIRECT = '/redirect',
  REDIRECT_NAME = 'Redirect',
  // Base Home
  BASE_WELCOME_HOME = '/',
  BASE_HOME = '/my-workouts',
  // Base Home redirect
  BASE_HOME_REDIRECT = '/my-workouts',
  // Error Page
  ERROR_PAGE_NAME = 'NOT_FOUND',
  // password
  FORGOT_PASSWORD = '/forgotpwd',
  RESET_PASSWORD = '/resetpwd',
  ABOUT_US = '/about-us',
  FAQ = '/FAQ',
  PRIVACY_POLICY = '/privacy-policy',
  TERMS_OF_SERVICE = '/terms-of-service',
  COMPANIES = '/companies',
  MENTAL_FITNESS = '/mental-fitness',
  CAREERS = '/careers',
  PRICING = '/pricing',
  // SIGNUP
  SIGN_UP = '/signup',
  PAYMENT_STEP = '/payment-step',
  WAITING_LIST = '/waiting-list',
}
