// import { useUserStoreWidthOut } from '@/store/modules/user';
// import { ACCESS_TOKEN } from '@/store/mutation-types';
import { storage } from '@/common/storage';
// import { PageEnum } from '@/enums/pageEnum';
import { PageEnum } from '@/enums/pageEnum';
import { useUserStoreWidthOut } from '@/store/modules/user';
import { AUTHENTICATED } from '@/store/mutation-types';
import { Router } from 'vue-router';

const BASE_WELCOME_HOME = PageEnum.BASE_WELCOME_HOME;
const LOGIN_PATH = PageEnum.BASE_LOGIN;
const FORGOT_PASSWORD = PageEnum.FORGOT_PASSWORD;
const ABOUT_US = PageEnum.ABOUT_US;
const FAQ = PageEnum.FAQ;
const PRIVACY_POLICY = PageEnum.PRIVACY_POLICY;
const TERMS_OF_SERVICE = PageEnum.TERMS_OF_SERVICE;
const RESET_PASSWORD = PageEnum.RESET_PASSWORD;
const SIGN_UP = PageEnum.SIGN_UP;
const COMPANIES = PageEnum.COMPANIES;
const MENTAL_FITNESS = PageEnum.MENTAL_FITNESS;
const CAREERS = PageEnum.CAREERS;
const WAITING_LIST = PageEnum.WAITING_LIST;
const PRICING = PageEnum.PRICING;
const PAYMENT_STEP = PageEnum.PAYMENT_STEP;

// Pages that don't require authentication
const whitePathList: string[] = [
  BASE_WELCOME_HOME,
  LOGIN_PATH,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  ABOUT_US,
  FAQ,
  PRIVACY_POLICY,
  TERMS_OF_SERVICE,
  SIGN_UP,
  COMPANIES,
  MENTAL_FITNESS,
  CAREERS,
  WAITING_LIST,
  PRICING,
  PAYMENT_STEP,
];

// Pages that don't require subscription (but do require authentication)
const noSubscriptionRequiredList: string[] = [
  PAYMENT_STEP,
  '/profile',
  '/settings',
];

export function createRouterGuards(router: Router) {
  const userStore = useUserStoreWidthOut();

  router.beforeEach(async (to, _from, next) => {
    // console.log(to.fullPath, from.fullPath);
    // console.log(to);

    // test data start
    // userStore.setRecommendedWorkouts(rw);
    // test data end

    const title = to.meta.title;
    if (title) {
      document.title = title + ' - Upotential';
    }else{
      document.title = 'Upotential - The Gym for Mental Fitness';
    }

    let isAuth = storage.get(AUTHENTICATED);
    let found = router.options.routes.find((x) => {
      return to.name === x.name;
    });

    if (to.name === "schedule-request" || to.name === 'workout' || to.name === 'workout-detail' || to.name === 'rate-workout' || to.name == 'on-demand-detail') {
      next();
      return;
    }
    
    if (to.name === 'join' && !isAuth) {
      next();
      return;
    }
    
    // If not authenticated and trying to access protected route, redirect to login
    if (!whitePathList.includes(to.path) && !isAuth) {
      next({ name: PageEnum.BASE_LOGIN_NAME });
      
      // if (to.name === 'quiz') {

      //   if (_from.name === 'workout-detail') {
      //     next({ name: 'book-workout' });
      //     return;
      //   }
      //   return;
      // }
    }

    if (isAuth) {
      if(!userStore.getAuthenticated){
        try {
          await userStore.fetchCurrentUser();
        } catch (err) {
          console.log('[fetchCurrentUser]', err);
          next();
          return;
        }
      }
      
      // Check subscription status for authenticated users
      const subscriptionStatus = userStore.getSubscriptionStatus as any;
      const isSubscribed = subscriptionStatus?.isSubscribed || false;
      const skipRedirect = subscriptionStatus?.skipRedirect || false;
      
      // If not subscribed and trying to access a protected route that requires subscription
      const requiresSubscription = !noSubscriptionRequiredList.some(path => to.path.startsWith(path));
      
      if (!isSubscribed && !skipRedirect && requiresSubscription && to.path !== PAYMENT_STEP) {
        // Redirect to payment step
        next({ path: PAYMENT_STEP });
        return;
      }
    }

    if (
      (to.name === 'login' || to.name === 'home' || to.name === 'signup' || to.name === 'join') &&
      isAuth
    ) {
      next(PageEnum.BASE_HOME);
    } else if (!found) {
      next({ name: PageEnum.ERROR_PAGE_NAME });
    } else {
      next();
    }
  });

  router.onError((error) => {
    console.log(error, 'router error');
  });
}
