<template>
  <div class="nav-container" :class="{
    'home': miniMode,
    'not-home': !miniMode,
    'initializing': pageState.initializing,
    'hide': hide,
  }">
    <nav v-if="isAuth && !route.params.code" class="py-0 navbar fixed-top up-nav" :class="{
      scrolled: true,
    }">
      <router-link class="navbar-brand" :class="{ 'mobile-opacity-0': pageState.expanded }" to="/">
        <img class="nav-img" width="100" height="100" src="~@/assets/images/logo_green.svg" />

      </router-link>

      <div class="header-nav-right position-relative me-3 d-flex align-items-center">
        <ul class="nav d-flex me-4">
          <li class="nav-item">
            <router-link class="py-0 nav-link" to="/my-workouts">
              <span>My activity</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="py-0 nav-link" to="/book-workout">
              <span>Live schedule</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="py-0 nav-link" to="/on-demand">
              <span>On Demand</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="py-0 nav-link" to="/all-workouts">
              <span>All Workouts</span>
            </router-link>
          </li>
        </ul>
        <div class="user-actions" :class="{ expand: pageState.expanded }" @click="toggleAction">
          <span class="level-up-icon" :class="[`level-up-icon-${user?.level}`]">
            <SvgIcon :name="`Level-${user?.level}`" />
          </span>
          <i class="text-white fa-regular fa-angle-down"></i>
        </div>
        <div class="expand-icon" @click="toggleExpand">
          <i class="nav-expand-btn" :class="{ expand: pageState.expanded }">
            <span></span>
          </i>
        </div>
      </div>

      <div id="navbar-toggle-collapse" class="collapse up-nav-collapse" is-nav>
        <ul class="ml-auto navbar-nav"></ul>
      </div>
    </nav>

    <ProfileInfo v-if="pageState.expanded" />

    <div v-if="!isAuth" class="logged-out-nav" :class="{
      'scrolled-more': pageState.scrolledMore,
      'scrolled': pageState.scrolled,
      'no-auth-bg': route.name === 'workout-detail',
    }">

      <i v-if="!pageState.public_expanded" @click="pageState.public_expanded = true"
        class="mobile-only open-nav-icon fa-sharp fa-regular fa-bars"></i>

      <ul class="user-controls">
        <li class="login-btn">
          <router-link @click="pageState.public_expanded = false"
            v-bind:to="'/login' + (route.query.redirect ? '?redirect=' + route.query.redirect : '')"> Login
          </router-link>
        </li>
        <!-- <li class="signup-btn">
          <router-link @click="pageState.public_expanded = false"
            v-bind:to="'/signup' + (route.query.redirect ? '?redirect=' + route.query.redirect : '')"> Sign
            Up </router-link>
        </li> -->
        <li class="signup-btn">
          <router-link @click="pageState.public_expanded = false"
            v-bind:to="'/signup' + (route.query.redirect ? '?redirect=' + route.query.redirect : '')"> Sign up
          </router-link>
        </li>
      </ul>

      <router-link class="desktop-only navbar-logo" :class="{ 'mobile-opacity-0': pageState.expanded }" to="/">
        <img class="" src="~@/assets/images/logo_black.svg" />
      </router-link>

      <div class="floating-nav">

        <router-link class="mobile-only navbar-logo" :class="{ 'mobile-opacity-0': pageState.expanded }" to="/">
          <img class="nav-img" src="~@/assets/images/logo_black.svg" />
        </router-link>

        <div class="outer-login-btn">
          <router-link v-bind:to="'/login' + (route.query.redirect ? '?redirect=' + route.query.redirect : '')"> Login
          </router-link>
        </div>


        <nav :class="{
          'expanded': pageState.public_expanded,
        }">
          <i @click="pageState.public_expanded = false"
            class="mobile-only close-nav-icon fa-sharp fa-regular fa-xmark"></i>
          <ul>
            <li class="nav-item">
              <router-link class="nav-link-text" @click="pageState.public_expanded = false" v-bind:to="'/mental-fitness'">
                Mental Fitness
              </router-link>
              <div class="expanded-nav-item">
                <ul>
                  <router-link @click="pageState.public_expanded = false" v-bind:to="'/mental-fitness'">
                    <li>
                      <h3>Mental Fitness</h3>
                      <p>Train your mental skills and learn how to enhance your mental well-being and perform at the level
                        you desire.</p>
                    </li>
                  </router-link>
                  <router-link @click="pageState.public_expanded = false" v-bind:to="'/mental-fitness#muscles'">
                    <li>
                      <h3>Mental Energy</h3>
                      <p>Recover and reload - go inward and connect your inner thoughts to your core.</p>
                    </li>
                  </router-link>
                  <router-link @click="pageState.public_expanded = false" v-bind:to="'/mental-fitness#muscles'">
                    <li>
                      <h3>Mental Flexibility</h3>
                      <p>Learn to approach opportunities and challenges from different angles - muscle up your growth
                        mindset.</p>
                    </li>
                  </router-link>
                  <router-link @click="pageState.public_expanded = false" v-bind:to="'/mental-fitness#muscles'">
                    <li>
                      <h3>Mental Strength</h3>
                      <p>Challenge yourself, your emotions and your thoughts - strengthen your resilience and grit.</p>
                    </li>
                  </router-link>
                  <!-- <li>
                    <h3>Get certified</h3>
                    <p>Work out regularly to earn certifications and take your mental skills to new levels!</p>
                  </li> -->
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <router-link class="nav-link-text" @click="pageState.public_expanded = false" v-bind:to="'/'">
                Products
              </router-link>
              <div class="expanded-nav-item">
                <ul>
                  <!-- <router-link @click="pageState.public_expanded = false" v-bind:to="'/signup'">
                    <li>
                      <h3>Sign up</h3>
                      <p>Sign up, book a personal introduction call and you’ll know more about how you can benefit from
                        training your mental skills and where to start.</p>
                    </li>
                  </router-link> -->
                  <router-link @click="pageState.public_expanded = false" v-bind:to="'/pricing#programs'">
                    <li>
                      <h3>Training Programs</h3>
                      <p>Customized training programs delivered in-person or online.</p>
                    </li>
                  </router-link>
                  <router-link @click="pageState.public_expanded = false" v-bind:to="'/pricing#licenses'">
                    <li>
                      <h3>Training Licenses</h3>
                      <p>
                        Train your mental skills in our LIVE workouts or whenever you want using our On Demand
                        workouts.
                      </p>
                    </li>
                  </router-link>
                  <!-- <li>
                    <h3>Membership</h3>
                    <p>Become Upotential member and get access to online mental workouts (live).</p>
                  </li> -->
                  <router-link @click="pageState.public_expanded = false" v-bind:to="'/#reviews'">
                    <li>

                      <h3>Reviews</h3>
                      <p>Listen to how people from all over the world have benefited from training their mental skills.
                      </p>

                    </li>
                  </router-link>
                </ul>
              </div>
            </li>
            <li class="nav-item negative">
              <router-link class="nav-link-text" @click="pageState.public_expanded = false" v-bind:to="'/about-us'">
                About us
              </router-link>
              <div class="expanded-nav-item">
                <ul>
                  <router-link @click="pageState.public_expanded = false" v-bind:to="'/about-us'">
                    <li>
                      <h3>About us</h3>
                      <p>We’re on a mission to inspire people to keep life healthy and fun!</p>
                    </li>
                  </router-link>
                  <router-link @click="pageState.public_expanded = false" v-bind:to="'/careers'">
                    <li>
                      <h3>Careers</h3>
                      <p>Join us and be part of an impactful change.</p>
                    </li>
                  </router-link>
                  <!-- <li>
                    <h3>News and press</h3>
                    <p>Read the buzz about Upotential.</p>
                  </li> -->
                  <!-- <li>
                    <h3>An Army for Mental Fitness</h3>
                    <p>Meet our people who are united for changing the world to the better.</p>
                  </li> -->
                </ul>
              </div>
            </li>
            <li class="nav-item negative">
              <router-link class="nav-link-text" @click="pageState.public_expanded = false" v-bind:to="'/companies'">
                Book Demo
              </router-link>
            </li>
            <li class="mobile-only nav-item negative">
              <router-link class="nav-link-text" @click="pageState.public_expanded = false" v-bind:to="'/signup'">
                Sign up
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SvgIcon from './SvgIcon.vue';
import ProfileInfo from '@/components/ProfileInfo.vue';

import { reactive, computed, onMounted, watch } from 'vue';
import { useUserStore } from '@/store/modules/user';
import { useRoute } from 'vue-router';

const userStore = useUserStore();
const route = useRoute();


const isAuth = computed(() => {
  return userStore.getAuthenticated;
});

const user = computed(() => {
  return userStore.getUser;
});

const miniMode = computed(() => {

  const miniModeViews = [
    'home',
    'login',
    'signup',
    'payment-step',
    'join',
    'forgotpwd',
    'reset-password',
    'rate-workout',
    'resetpwd',
    'waiting-list',
  ]

  if (miniModeViews.includes(route.name as string)) {
    return true;
  } else {
    return false;
  }
});

const hide = computed(() => {
  const hideViews = [
    'quiz',
    'payment-step',
    'signup',
  ]

  if (hideViews.includes(route.name as string)) {
    return true;
  } else {
    return false;
  }
});

const pageState = reactive({
  visible: false,
  scrolled: false,
  scrolledMore: false,
  navbarExpanded: false,
  public_expanded: false,
  pcExpanded: false,
  expanded: false,
  profileContracted: true,
  initializing: true,
});

const onScroll = () => {
  if (window.scrollY > 0) {
    pageState.scrolled = true;
  } else {
    pageState.scrolled = false;
  }

  if (window.scrollY > 300) {
    pageState.scrolledMore = true;
  } else {
    pageState.scrolledMore = false;
  }
};

const toggleAction = () => {
  pageState.expanded = !pageState.expanded;
};
const toggleExpand = () => {
  pageState.profileContracted = !pageState.profileContracted;
  pageState.navbarExpanded = !pageState.navbarExpanded;
  pageState.expanded = !pageState.expanded;
};

onMounted(async () => {
  document.addEventListener('scroll', onScroll);

  await new Promise((resolve) => setTimeout(resolve, 500));

  pageState.initializing = false;
});

watch(
  () => route,
  () => {
    pageState.profileContracted = true;
    pageState.navbarExpanded = false;
    pageState.expanded = false;
  },
  {
    deep: true,
  }
);
</script>

<style lang="scss" scoped>
.user-controls {
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 1001;
}

.scrolled .expanded-nav-item,
.not-home .expanded-nav-item {
  margin-top: calc(env(safe-area-inset-top) + 70px);
}

.expanded-nav-item {
  position: absolute;
  margin-top: calc(env(safe-area-inset-top) + 90px);
  top: 0px;
  left: 0;
  width: 100%;
  background-color: #fff0;

  pointer-events: none;
  transition: all 0.3s;
  z-index: 1;
  text-align: left;

  li {
    cursor: pointer;
    max-width: 300px;
  }

  ul {
    opacity: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 0px !important;
  }

  h3 {
    font-size: 16px;
  }

  p {
    font-family: SharpGrotesk;
    font-size: 12px;
    font-weight: normal;
  }


}

.nav-link-text {
  position: relative;
  display: block;
  z-index: 3;
}

.expanded-nav-item:hover {
  background-color: white;
  pointer-events: all;

  ul {
    opacity: 1;
  }

}

.nav-item:hover .expanded-nav-item {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
}

.nav-item:hover a.nav-link-text {
  color: #00219e !important;
}


.expanded-nav-item li:hover {
  color: #00219e;
}

.nav-item {
  height: 60px;
}

.nav-item.negative a {
  color: black !important;
}

.nav-item:hover .expanded-nav-item {
  pointer-events: all;
  background-color: white;

  ul {
    opacity: 1;
  }
}


.nav-container.hide {
  display: none;
}

.not-home.nav-container {
  margin-top: calc(70px);
}

.navbar {
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  height: calc(64px + env(safe-area-inset-top));
  z-index: 100;
  padding-top: env(safe-area-inset-top) !important;
}

.navbar-logo {
  position: fixed;
  top: 10px;
  left: 10px;
  opacity: 0;
  transition: all 0.3s;

  img {
    height: 54px;
    width: auto;
  }

  z-index: 1000;
}

.scrolled .navbar-logo,
.not-home .navbar-logo {
  opacity: 1;
}

.scrolled-more .floating-logo {
  opacity: 1;
  transform: scale(1) rotate(0deg);
}

.nav-img {
  height: 48px;
  width: auto;
  margin-top: 0px;
}

.navbar-brand {
  transition: all 0.3s;
  padding: 0;
  margin: 0 24px;
}

.expand-icon {
  display: none;
  align-items: center;
  margin-right: 24px;
  cursor: pointer;

  .fa-solid.fa-bars,
  .bi.bi-plus-lg {
    position: relative;
    z-index: 1;
  }

  .nav-expand-btn {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 24px;
    position: relative;

    span {
      display: inline-block;
      width: 40px;
      height: 2px;
      background-color: #fff;
      border-radius: 2px;
    }

    &::before,
    &::after {
      content: ' ';
      display: inline-block;
      width: 40px;
      height: 2px;
      background-color: #fff;
      border-radius: 2px;
      position: absolute;
      left: 0;
      transition: all 0.3s;
    }

    &::before {
      top: 0px;
    }

    &::after {
      bottom: 0px;
    }

    &.expand {
      span {
        display: none;
      }

      &::before {
        top: 11px;
        transform: rotateZ(45deg);
      }

      &::after {
        bottom: 11px;
        transform: rotateZ(-45deg);
      }
    }
  }
}

.up-nav {
  background-color: rgba(63, 225, 184, 0);
  transition: 0.3s all;

  .position-relative {
    .bi.bi-chevron-down.text-white {
      display: inline-block;
      width: 12px;
      height: 8px;
    }

    .nav {
      .nav-item {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .nav-link {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          color: #fff;
          font-family: SharpGroteskSm;
          position: relative;

          span {
            position: relative;
          }

          &:hover,
          &.router-link-active {
            font-size: 16px;

            & span {
              transform: translateY(-5px);

              &::after {
                content: ' ';
                display: inline-block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 4px;
                transform: translateY(0.5rem);
                background-color: rgba(255, 201, 24, 1);
              }
            }
          }
        }
      }
    }

    .user-actions {
      display: inline-flex;
      align-items: center;
      cursor: pointer;

      .level-up-icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        margin-right: 6px;
        background-color: #e9f3fc;
        border-radius: 50%;

        .svg-icon {
          font-size: 36px;
        }
      }

      .fa-regular.fa-angle-down {
        transition: all 0.3s;
      }

      &.expand {
        .fa-regular.fa-angle-down {
          transform: rotateZ(180deg);
        }
      }
    }
  }
}

.up-nav.scrolled,
.up-nav.navbar-expanded {
  background-color: rgba(0, 33, 158, 0.9);

  .nav-img {
    transform: scale(1);
  }

  .expand-icon {
    color: white;
  }
}

.navbar-collapse.collapse.show {
  border-top: 2px solid rgb(0, 0, 0, 0.1);
}

.nav-link {
  width: 100%;
  font-size: clamp(20px, 1.5vw, 40px);
  color: white;
  text-align: center;
}

.up-nav-collapse {
  border-width: 1px;
  border-color: rgb(0, 0, 0, 1);
}

.expand-icon {
  width: 40px;
  height: 40px;
  z-index: 1;

  .nav-expand-btn {
    z-index: 1;
  }
}

.scrolled-more nav {
  opacity: 1;
}

nav {
  transition: 0.3s all ease;
  font-family: 'SharpGroteskSm';
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 50px;
  z-index: 999;
  text-align: right;
}

.login-btn,
.signup-btn {
  display: inline-block;
  padding: 10px 10px;
  font-weight: bold;
  color: white;
  font-size: 16px;
  border: 2px solid rgba(255, 255, 255, 0);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.1s ease;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  transition: all 0.3s ease;
  margin-top: 12px;

  a {
    color: white;
    text-decoration: none;
  }
}

.home .signup-btn {
  margin-top: 28px;
}

.home .scrolled .signup-btn {
  margin-top: 12px;
}

.signup-btn {
  background-color: #ff4611;
  border-radius: 4px;
  margin: 0 20px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.login-btn:hover {
  border-color: rgba(255, 255, 255, 1);
}

.signup-btn:hover {
  filter: brightness(120%);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px;
  transform: translateY(-2px);
}

.outer-login-btn {
  display: none;
}

.floating-nav {

  .nav-img {
    margin-top: env(safe-area-inset-top);
  }

  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: calc(70px + env(safe-area-inset-top));
  z-index: 999;
  transition: 0.3s all;
  box-shadow: #00000000 0px 0px 0px;

  ul {
    margin-top: calc(30px + env(safe-area-inset-top));
    margin-right: 25px;
    transition: 0.3s all;

    display: flex;
    flex-direction: row;
    justify-content: center;

    li {
      padding: 14px 15px;
    }
  }

  .nav-item a {
    text-decoration: none;
    transition: 0.3s all;
    margin-right: 10px;
    font-weight: bold;
  }
}

.home .floating-nav {
  height: calc(90px + env(safe-area-inset-top));
  background-color: #ffffff00;
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);

  .nav-item a {
    color: white;

  }
}

.scrolled .floating-nav {
  height: calc(70px + env(safe-area-inset-top));
  box-shadow: 0px 1px 2px #00000033;

  ul {
    margin-top: 10px;
    margin-right: 15px;
  }

  .nav-item a {
    color: black;
  }

}

.home .scrolled .floating-nav {
  height: calc(70px + env(safe-area-inset-top));
  background-color: #ffffffdd;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  box-shadow: 0px 1px 2px #00000033;

  ul {
    margin-top: 10px;
    margin-right: 15px;
  }

}

.not-home .floating-nav {
  height: calc(70px + env(safe-area-inset-top));
  background-color: white;

  .nav-item a {
    color: black;
  }

  ul {
    margin-top: 10px;
    margin-right: 15px;
  }

}

.floating-nav {
  pointer-events: none;
}

.floating-nav:hover {
  background-color: white;

  .nav-item {
    z-index: 2;
  }

  .nav-item a {
    color: black;
  }
}

.floating-nav {

  .nav-item,
  .login-btn,
  .signup-btn {
    pointer-events: auto;
  }
}

.open-nav-icon {
  position: fixed;
  top: calc(21px + env(safe-area-inset-top));
  right: 5px;
  width: 40px;
  height: 40px;
  z-index: 1000;
  color: black;
  font-size: 30px;
  transition: 0.3s all;
  pointer-events: auto;
}

.home .open-nav-icon {
  color: white;
}

.scrolled .open-nav-icon {
  color: black;
}

.close-nav-icon {
  position: absolute;
  top: calc(18px + env(safe-area-inset-top));
  right: 18px;
  width: 40px;
  height: 40px;
  z-index: 1001;
  color: black;
  font-size: 30px;
}

.mobile-only {
  display: none;
}

.desktop-only {
  display: inherit;
}

.not-home.scrolled .floating-nav {
  box-shadow: 0px 1px 2px #00000033;
}

.no-auth-bg {
  .floating-logo {
    opacity: 1;
    top: 5px;
    transform: rotate(0deg) translateY(5px);
  }

  .floating-nav {
    ul {
      margin-bottom: 30px;
    }
  }
}

.initializing * {
  transition: 0s all !important;
}

@media (max-width: 1200px) {

  .nav-item {
    height: auto;

    a {
      font-size: 20px;
    }
  }

  .nav-item:hover .expanded-nav-item {
    box-shadow: none;
  }

  .expanded-nav-item {
    margin-top: 10px !important;
    position: relative;
    display: block;

    ul {
      opacity: 1;

      h3 {
        font-size: 16px;
        font-weight: normal;
      }

      p {
        display: none;
      }
    }

    pointer-events: all;
  }

  .user-controls {
    display: none;
  }

  .outer-login-btn {
    pointer-events: auto;
    display: inline-block;
    position: absolute;
    top: calc(22px + env(safe-area-inset-top));
    right: 65px;

    a {
      color: white;
      text-decoration: none;
      padding: 15px;
      transition: 0.3s all;
    }
  }

  .scrolled .outer-login-btn,
  .not-home .outer-login-btn {
    a {
      color: black;
    }
  }

  .desktop-only {
    display: none;
  }

  .nav-img {
    height: 45px;
    width: auto;
  }

  .floating-nav {
    pointer-events: all;
    background-color: transparent;
  }

  .floating-nav:hover {
    background-color: transparent;
  }

  .floating-nav nav {
    height: 0px;
    background-color: white;
    padding-top: 0px;
    overflow: scroll;

    ul {
      margin-right: 10px;
    }

    li {
      display: inline-block;
      padding: 0px;
      font-weight: bold;
      font-size: 12px;
    }
  }

  nav.expanded {
    height: 100vh;
  }

  .home .nav-item a {
    color: black !important;
  }

  .floating-nav {

    height: calc(70px + env(safe-area-inset-top)) !important;

    ul {
      flex-direction: column;
      margin: 10px !important;
      padding: 0;
    }

    li {
      text-align: left;
      margin: 0;
      margin-top: 8px;
    }
  }

  .mobile-only {
    display: inherit;
  }

  .navbar {
    box-shadow: none;
    height: calc(72px + env(safe-area-inset-top));
  }

  .header-nav-right {
    margin-right: 0 !important;

    .expand-icon {
      display: inline-flex;
    }

    .user-actions,
    ul {
      display: none !important;
    }
  }

  .navbar-logo {
    z-index: 998;
  }

  .navbar-brand {
    &.mobile-opacity-0 {
      opacity: 0;
    }
  }

}
</style>
